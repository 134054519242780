/* Ref 5. in https://speckyboy.com/free-css-tooltip-snippets */
.qtip {
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: #3bb4e5;
  border-bottom: 0.05em dotted #3bb4e5;
  box-sizing: border-box;
  font-style: normal;
  transition:all .25s ease-in-out
}
.qtip:hover {color:#069;border-bottom:0.05em dotted #069}
/*the tip*/
.qtip:before {
  content: attr(data-tooltip);
  font-size: 14px;
  position: absolute;
  background: rgba(10, 20, 30, 0.85);
  color: #fff;
  line-height: 1.2em;
  padding: 0.5em;
  font-style: normal;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  min-width: 120px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-family: sans-serif;
  letter-spacing: 0;
  font-weight: 600
}
.qtip:after {
  width: 0;
  height: 0;
  border-style: solid;
  content: '';
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out
}
.qtip:hover:before,
.qtip:hover:after {
  visibility: visible;
  opacity: 1
}
/*top*/
.qtip.tip-top:before {
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, calc(-100% - 8px));
          transform: translate(-50%, calc(-100% - 8px));
  box-sizing: border-box;
  border-radius: 3px;
}
.qtip.tip-top:after {
  border-width: 8px 8px 0 8px;
  border-color: rgba(10, 20, 30, 0.85) transparent transparent transparent;
  top: -8px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}